// Hide Show Password
$(".togglePassword").click(function () {
    $(this).toggleClass("icon-base icon2-base");
    var toggleInput = $(this).parent().find('.input')
    if (toggleInput.attr("type") == "password") {
        toggleInput.attr("type", "text");
    } else {
        toggleInput.attr("type", "password");
    }
});

$('.rb-select').click(function() {
    $(this).parents('.cb-select-wrapper').find('.cb-select-label').text($(this).find('.rb-select__box').text());
})