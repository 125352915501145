// tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

// remove transition preload
$("body").removeClass("preload");

// Input focus
$(".input-wrap input, .input-wrap textarea").focus(function () {
    $(this).parent(".input-wrap").addClass("_focused");
});
$(".input-wrap input, .input-wrap textarea").focusout(function () {
    $(this).parent(".input-wrap").removeClass("_focused");
});

// Input Dropdown
$(document).click(function () {
    $(".input-pack").removeClass("_dropdown");
});

$(".pseuinput").click(function (e) {
    e.stopPropagation();
    $(this).parents(".input-pack").toggleClass("_dropdown");
});

//
$('.input-pack__dropdown__list li input[type="radio"]').click(function () {
    $(this).parents('.input-pack__dropdown__list').find('li').removeClass('_active');
    $(this).parents('li').addClass('_active');

    $getSelectedName = $(this).parents('label').find('span').text();
    $(this).parents('.input-pack').find('.input-wrap .pseuinput span').text($getSelectedName);
});

//
$('input[type="file"]').change(function (e) {
    var fileName = e.target.files[0].name;
    $('.lobel').html(fileName)
});

// Currency Formatter
$("input[data-type='currency']").on({
    keyup: function () {
        formatCurrency($(this));
    },
    // blur: function () {
    //     formatCurrency($(this), "blur");
    // }
});

function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function formatCurrency(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") { return; }

    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(",") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "00";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        // input_val = "Rp" + left_side + "," + right_side;
        input_val = left_side + "," + right_side;

    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        // input_val = "Rp " + input_val;
        input_val = input_val;

        // final formatting
        // if (blur === "blur") {
        //     input_val += ".00";
        // }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
}

// Drawer Menu
// $('.navbar__button--drawer-btn').click(function () {
//     $('body').toggleClass('overflow-hidden');
//     $('.drawer-menu').toggleClass('_active');
//     $('.navbar__button--drawer-btn .fa').toggleClass('fa-bars');
//     $('.navbar__button--drawer-btn .fa').toggleClass('fa-times');
// });

//Number Separate
function numberWithCommas(number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
}
$(document).ready(function () {
    $(".ns").each(function () {
        var num = $(this).text();
        var commaNum = numberWithCommas(num);
        $(this).text(commaNum);
    });
});

$('.with-loading').click(function () {
    $this = $(this);

    $this.find('b').hide();
    $this.css('opacity', '.6').prepend(`<b class="fa fa-circle-o-notch fa-spin fa-fw"></b> `);

    setTimeout(function () {
        $this.css('opacity', '1').find('b').hide();
    }, 10000);
});

// Terms
$('#terms input').click(function() {
    if ($(this).is(':checked')) {
        $(this).parents('form').find('.submit').removeAttr('disabled');
    } else {
        $(this).parents('form').find('.submit').attr('disabled', true);
    }
})