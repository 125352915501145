// Upload Img
function readURL(input) {
	if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
    	$('#uploadImg').attr('src', e.target.result);
    }
    reader.readAsDataURL(input.files[0]);
	}
}

$("#uploadImgBtn").change(function(){
	readURL(this);
});

//Remove Img
function removeImg()
{
var img = document.getElementById("uploadImg");
img.src="img/logo.svg";
return false;
}

//
$(document).ready(function(){
    $('#uploadImgBtn').change(function(e){
        var fileName = e.target.files[0].name;
        $('.upload-file__name').html(fileName);
    });
});