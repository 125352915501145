$(document).ready(function () {
    $('.progress').each(function () {

        prog = $(this).outerWidth();
        progBar = $(this).find('.progress-bar').outerWidth();
        progResult = prog - progBar;

        if (progResult < 10) {
            $(this).find('.progress-bar').addClass('ofr');
        } else if (progBar < 10) {
            $(this).find('.progress-bar').addClass('ofl');
        }
    });

    // $('.progress').mouseover();
});