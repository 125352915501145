$('input[type="checkbox"]').click(function() {
    const thisName = $(this).attr('name');

    // if this attr data-cb="parent", unchecked all data-cb="child"
    if ($(this).attr('data-cb') === 'parent') {
        $('input[data-cb="child"][name="'+thisName+'"]').prop('checked', false);
    } else if ($(this).attr('data-cb') === 'child') {
        // if this attr data-cb="child", unchecked all data-cb="parent"
        $('input[data-cb="parent"][name="'+thisName+'"]').prop('checked', false);
    }

    // checkbox reverse

    if ($(this).attr('data-cb') === 'parent-reverse') {
        if ($(this).prop('checked')) {
            $('input[data-cb="child-reverse"][name="'+thisName+'"]').prop('checked', true);
        } else {
            $('input[data-cb="child-reverse"][name="'+thisName+'"]').prop('checked', false);
        }

        const countThisChildChecked = $('input[data-cb="child-reverse"][name="'+thisName+'"]:checked').length;

        if (countThisChildChecked > 0) {
            $('.delete-all[name="'+thisName+'"]').removeClass('text-gray').addClass('text-danger').removeAttr('disabled');
        } else {
            $('.delete-all[name="'+thisName+'"]').removeClass('text-danger').addClass('text-gray').attr('disabled"', 'disabled');
        }
    } else if ($(this).attr('data-cb') === 'child-reverse') {
        const countThisChildChecked = $('input[data-cb="child-reverse"][name="'+thisName+'"]:checked').length;

        const countThisChild = $('input[data-cb="child-reverse"][name="'+thisName+'"]').length;
        if (countThisChildChecked > 0) {
            $('.delete-all[name="'+thisName+'"]').removeClass('text-gray').addClass('text-danger').removeAttr('disabled');
        } else {
            $('.delete-all[name="'+thisName+'"]').removeClass('text-danger').addClass('text-gray').attr('disabled', 'disabled');
        }

        if (countThisChildChecked === countThisChild) {
            $('input[data-cb="parent-reverse"][name="'+thisName+'"]').prop('checked', true);
        } else {
            $('input[data-cb="parent-reverse"][name="'+thisName+'"]').prop('checked', false);
        }
    }
})