$(window).scroll(function() {
    if ($(this).scrollTop() > 1) {
        $('.navbar').addClass('border-base-light-30').removeClass('border-white');
    } else {
        $('.navbar').removeClass('border-base-light-30').addClass('border-white');
    }

    function breakpoint(breakpointLg) {
        if (breakpointLg.matches) {
            // Navbar
            if ($(this).scrollTop() > 1) {
                $('#logoNavbar').css('transform', 'translateY(-5.125rem)');
            } else {
                $('#logoNavbar').css('transform', 'translateY(0)');
            }
        }
    }

    var breakpointLg = window.matchMedia("(min-width: 1211px)");
    breakpoint(breakpointLg)
    breakpointLg.addListener(breakpoint)
});